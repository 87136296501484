
import { Options, Vue } from "vue-class-component";
import { IReview } from "@/types/Review";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import * as yup from "yup";
import Datepicker from "@vuepic/vue-datepicker";
import { notify } from "@/services/helpers";

@Options({
  name: "InviteReview",
  components: {
    Breadcrumb,
    Form,
    Field,
    ErrorMessage,
    ConfirmationModal,
    Datepicker,
  },
  data: function () {
    return {
      loading: false,
      message: "",
      schema: yup.object().shape({
        reviewDate: yup.string().required("Please provide date & time."),
        comments: yup
          .string()
          .required("Please provide a comment.")
          .max(250, "Must not exceed 250 characters in length"),
      }),
    };
  },

  watch: {},
})
export default class InviteReview extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  newReview: IReview = {
    id: 0,
    date: "",
    comments: "",
    type: "",
    employeeId: 0,
  };
  crumbs: any = ["Dashboard", "Employees", "Review"];
  loading = false;

  message = "";

  convertTwoDigit(val: any) {
    const len = val.toString().length;
    if (len == 1) {
      return `0${val}`;
    }
    return val;
  }

  formatDateTime() {
    let dateTime = new Date(this.newReview.date.toString().substr(0, 21));
    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1;
    const year = dateTime.getFullYear();
    const hour = dateTime.getHours();
    const min = dateTime.getMinutes();

    return `${year}-${this.convertTwoDigit(month)}-${this.convertTwoDigit(
      day
    )}T${this.convertTwoDigit(hour)}:${this.convertTwoDigit(min)}:00`;
  }

  async save() {
    this.loading = true;
    this.message = "";
    this.newReview.date = this.formatDateTime();
    await this.workForceService
      .postOrPut<void>("/reviews", this.newReview as IReview, undefined)
      .then((response: any) => {
        this.loading = false;
        if (!response.isError) {
          notify("Invitation sent successfully.", "Success", "success");

          this.$router.back();
        } else {
          notify(response.errors, "Error", "danger");
        }
      });
  }

  async created() {
    this.newReview.employeeId = +this.$route.params.id;
  }

  async goToRoute(route: string) {
    await this.$router.push(route);
  }
}
